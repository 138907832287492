import * as React from 'react';
import styled from 'styled-components';
import CheckListItem from '../atoms/CheckListItem';

type CheckListProps = {
    titleSize: "small" | "big";
    title: string;
    list: string[];
}

const CheckListWrapper = styled.div`	
    padding: ${({ theme }) => theme.paddingDesktop}; 
    padding-top: 30px;

    @media (max-width: 1600px) {
        padding: 0; 
        padding-top: 30px;
    }
`;

const StyledCheckList = styled.ul`	
    display: flex;
    flex-direction: column;
    margin: 0;
`;

const TitleBig = styled.h2`
	width: 100%;
	color: ${({ theme }) => theme.primaryColor};
	margin: 0 0 50px 0;
`;
const TitleSmall = styled.h3`
	width: 100%;
	color: ${({ theme }) => theme.primaryColor};
	margin: 0 0 50px 0;
`;



const CheckList: React.FC<CheckListProps> = ({ titleSize, title, list }) => {

    return ( 
        <CheckListWrapper>
            {titleSize === "small" ? <TitleSmall>{title}</TitleSmall> : <TitleBig>{title}</TitleBig>}
            <StyledCheckList>
                {list.map((item, index) => <CheckListItem key={index} text={item}/>)}
            </StyledCheckList>
        </CheckListWrapper>
    );
}
 
export default CheckList;