import styled from 'styled-components';
import React from 'react';
import CheckImageUrl from "../../images/check.svg";

type CheckListItemType = {
    text: string
}

export const StyledCheckListItem = styled.span`
    margin-left: 32px;
`

const CheckImage = styled.img`
    width: 23px;
    height: 13px;
`

export const CheckListItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 40px;
`

const CheckListItem: React.FC<CheckListItemType> = ({ text }) => (
    <CheckListItemWrapper>
        <CheckImage src={CheckImageUrl} alt="check"/>
        <StyledCheckListItem>{text}</StyledCheckListItem>
    </CheckListItemWrapper>
)

export default CheckListItem;