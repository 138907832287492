import * as React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import ButtonAnchor from '../atoms/ButtonAnchor';
import Button from '../atoms/Button';
import ContactPopUp from '../organisms/ContactPopUp';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { WpUser } from '../../shared/wp-user-utils';

type ContainerWithButtonProps = {
    title: string;
    text: string | React.ReactNode;
    buttonText?: string;
    buttonHref?: string;
    buttonType?: "white" | "blue";
    author?: string;
    phoneNumber?: string;
    email?: string;
    user?: WpUser;
}

const StyledContainerWithButton = styled.div`	
    display: flex;
    flex-direction: column;
    padding: 32px 24px 46px 24px; 
    box-shadow: ${({ theme }) => theme.boxShadow}; 
    border-radius: 16px;
    background-color: ${({ theme }) => theme.whiteColor};
    width: 444px;
`;


const Title = styled.h4`
	width: 100%;
	margin: 0 0 32px 0;
`;

const Text = styled.span`
	width: 100%;
	margin: 0 0 32px 0;
    min-height: 100px;
`;

const StyledButtonAnchor = styled(ButtonAnchor)`
	width: 100%;
`;

const StyledButton = styled(Button)`
    :first-letter {
        text-transform: uppercase
    }
    max-width: 190px;
    @media (max-width: 767.98px) {
        max-width: 100%;
    } 
`;

const ContainerWithButton: React.FC<ContainerWithButtonProps> = ({ title, text, buttonText, buttonHref, buttonType, author, phoneNumber, email, user }) => {
    const {t} = useTranslation();
    const photo = user && user.user?.photo?.localFile.childImageSharp.fluid;

    return ( 
        <StyledContainerWithButton>
            <Title>{title}</Title>
            <Text>{text}</Text>
            {buttonText && <StyledButtonAnchor href={buttonHref} type={buttonType}>{buttonText}</StyledButtonAnchor>}
            {(author && user) && <Popup trigger={<StyledButton type="blue">{t('makeContact')}</StyledButton>} modal>
             { close => <ContactPopUp photo={photo} author={author} close={close} phoneNumber={phoneNumber} email={email}/>}
            </Popup>}
        </StyledContainerWithButton>
    )
}
 
export default ContainerWithButton;