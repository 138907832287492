import * as React from 'react';
import Layout from '../../components/layout';
import { Header } from '../../components/organisms/Header';
import NeedMore from '../../components/organisms/NeedMore';
import { graphql } from 'gatsby';
import PageContent from '../../components/organisms/PageContent';
import AboutList from '../../components/organisms/AboutList';
import NewestNews from '../../components/organisms/NewestNews';
import DataboutTools from '../../components/organisms/DataboutTools';
import CheckList from '../../components/organisms/CheckList';
import styled from 'styled-components';
import { StyledHarmonyContent } from '../../components/organisms/HarmonyContent';
import DronyExamples from '../../components/organisms/DronyExamples';
import SEO from '../../components/seo';
import uavImage from '../../images/index/uav-index.svg';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { getPostModel, WpPost } from '../../shared/wp-post-utils';
import { defaultLanguage } from '../../../site-config';
import { ImageSharp } from '../../shared/image-sharp-utils';

const DronyContentWrapper = styled(StyledHarmonyContent)`
  padding-top: 0;
`

type DronesItem = {
    drony?: {
      aboutlisttitle: string;
      description: string;
      dronesexamplesbuttonhref: string;
      dronesexamplesbuttontext1: string;
      dronesexamplesbuttontext2: string;
      dronesexamplessubtitle1: string;
      dronesexamplessubtitle2: string;
      dronesexamplestext1: string;
      dronesexamplestext2: string;
      dronesexamplestitle1: string;
      dronesexamplestitle2: string;
      dronesitemdescription1: string;
      dronesitemdescription2: string;
      dronesitemdescription3: string;
      dronesitemdescription4: string;
      dronesitemtitle1: string;
      dronesitemtitle2: string;
      dronesitemtitle3: string;
      dronesitemtitle4: string;
      fieldGroupName: string;
      listitem1: string;
      listitem2: string;
      listitem3: string;
      listitem5: string;
      listitem4: string;
      listitem6: string;
      listtitle: string;
      subtitle: string;
      title: string;
      dronesitemimg1: {
          localFile: {
              childImageSharp: ImageSharp;
          }
      }
      dronesitemimg2: {
          localFile: {
              childImageSharp: ImageSharp;
          }
      }
      dronesitemimg3: {
          localFile: {
              childImageSharp: ImageSharp;
          }
      }
      dronesitemimg4: {
          localFile: {
              childImageSharp: ImageSharp;
          }
      }
  }
};

type DronesData = {
    data: {
      parent: WpPost & DronesItem;
      children: {
          nodes: WpPost[];
      };
      newestNews: {
          nodes: WpPost[];
      };
  };
  location?: any;
}

const Drony: React.FC<DronesData> = ({data, location}) => {
    const {t} = useTranslation();
    const {language} = useI18next();
    const LDAPHeaderUser = JSON.parse(data.parent.author.node.ldapUser)[0];

    const {title, aboutlisttitle, description, dronesitemdescription1, dronesitemdescription2, dronesitemdescription3, 
          dronesitemdescription4, dronesitemtitle1, dronesitemtitle2, dronesitemtitle3, dronesitemtitle4, 
          listitem1, listitem2, listitem3, listitem5, listitem4, listitem6, listtitle, subtitle, dronesitemimg1,
          dronesitemimg2, dronesitemimg3, dronesitemimg4} = data.parent.drony;

    const AboutDronyItems = [
      {
        title: dronesitemtitle1,
        content: <span>{dronesitemdescription1}</span>,
        imageUrl: dronesitemimg1.localFile.childImageSharp.fixed

      },
      {
        title: dronesitemtitle2,
        content: <span>{dronesitemdescription2}</span>,
        imageUrl: dronesitemimg2.localFile.childImageSharp.fixed
      },
      {
        title: dronesitemtitle3,
        content: <span>{dronesitemdescription3}</span>,
        imageUrl: dronesitemimg3.localFile.childImageSharp.fixed
      },
      {
        title: dronesitemtitle4,
        content: <span>{dronesitemdescription4}</span>,
        imageUrl: dronesitemimg4.localFile.childImageSharp.fixed
      },
    ]

    const CheckListItems = [ listitem1, listitem2, listitem3, listitem4, listitem5, listitem6]

    return ( 
        <Layout>
            <SEO title={title} description={description}/>
            <Header
                title={title} 
                description={description}
                subTitle={subtitle}
                headerImage={uavImage}
                headerImageWidth={800}
				        isSignature={true}
                user={data.parent.author.node}
                signatureAuthor={LDAPHeaderUser.name}
                signaturePosition={LDAPHeaderUser.title}
                signatureDeskPhoneNumber={LDAPHeaderUser.telephoneNumber?.[0]}
                signaturePhoneNumber={LDAPHeaderUser.mobile?.[0]}
                signatureEmail={LDAPHeaderUser.mail}
                signatureCity={LDAPHeaderUser.physicalDeliveryOfficeName}
                signatureShareLink={location.href}
                isArrowNavVisible={true}
                firstHeaderNavLink="uslugi"
                firstHeaderNavLinkName={title}
                SEOTitle={title}
                SEODescription={description}
            />
            <PageContent>
              <AboutList list={AboutDronyItems} title={aboutlisttitle} />
              <DronyContentWrapper>
                <CheckList title={listtitle} titleSize="big" list={CheckListItems}/>
                <DronyExamples 
                  author={LDAPHeaderUser.name} 
                  phoneNumber={LDAPHeaderUser.mobile?.[0]} 
                  email={LDAPHeaderUser.mail}
                  user={data.parent.author.node}
                  data={data}
                />
              </DronyContentWrapper>
              {data.newestNews.nodes.length > 0 && language === defaultLanguage && <NewestNews posts={data.newestNews.nodes.map(getPostModel)}/>}
              <DataboutTools title="Databout.Tools" subTitle={t('toolsAd.subTitle')} description={t('toolsAd.description')} link="/tools"/>
              <NeedMore/>
            </PageContent>
        </Layout>

     );
}
 
export default Drony;

export const pageQuery = graphql`
query($language: String!) {
  parent: wpPage(title: {in: ["Drony", "UAV"]}, language: {slug: {eq: $language}}) {
		...AuthorPage
    ...DronesData
	}
  newestNews: allWpPost(
    sort: {fields: dateGmt, order: DESC}
    filter: {categories: {nodes: {elemMatch: {name: {eq: "News"}}}}, language: {slug: {eq: $language}}, tags: {nodes: {elemMatch: {name: {eq: "drony"}}}}}
    limit: 3
	) {
		nodes {
      ...PageItems
		}
	}
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`