import * as React from 'react';
import styled from 'styled-components';
import ContainerWithButton from '../../components/molecules/ContainerWithButton';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { WpUser } from '../../shared/wp-user-utils';
import { WpPost } from '../../shared/wp-post-utils';

type DronyExamplesProps = {
    author: string;
    phoneNumber?: string;
    email: string;
    user: WpUser;
    data: {
		parent: WpPost,
		children: {
			nodes: WpPost[];
		},
		newestNews: { 
			nodes: WpPost[];
		}
	}	
}

const StyledDronyExamples = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.paddingDesktop}; 
    padding-top: 65px;
    padding-bottom: 110px;
    flex-wrap: wrap;

    @media (max-width: 1600px) {
        padding: 0; 
        padding-top: 65px;
        padding-bottom: 110px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: 0; 
    }

    div:nth-of-type(2) {
        margin-left: 115px;
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
        div:nth-of-type(2) {
            margin-left: 50px;
        }
    }

    @media (min-width: 1px) and (max-width: 1199.98px) {
        div:nth-of-type(2) {
            margin-left: 0;
        }

        div:nth-of-type(1) {
            margin-bottom: 0px;
        }
    }

`

const DronyExampleTitle = styled.h3`
    color: ${({ theme }) => theme.primaryColor}; 
`

const DronyExample = styled.div`
     display: flex;
     flex-direction: column;

     div {
         width: 500px;
         a {
             max-width: 190px;
         }
     }

     @media (min-width: 1200px) and (max-width: 1600px) {
        div {
            width: 500px;
        }
    }

    @media (max-width: 767.98px) {
        div {
            width: 100%;
            a {
             max-width: 100%;
         }
        }
    }
`

const DronyExamples: React.FC<DronyExamplesProps> = ({author, phoneNumber, email, user, data}) => {
    const {t} = useTranslation();

    const {dronesexamplesbuttonhref, dronesexamplesbuttontext1, dronesexamplessubtitle1, 
        dronesexamplessubtitle2, dronesexamplestext1, dronesexamplestext2, dronesexamplestitle1, 
        dronesexamplestitle2} = data.parent.drony

    return ( 
        <StyledDronyExamples>
             <DronyExample>
                    <DronyExampleTitle>{dronesexamplestitle1}</DronyExampleTitle>
                    <ContainerWithButton 
                      buttonType="white" 
                      text={dronesexamplestext1}
                      title={dronesexamplessubtitle1}
                      buttonText={dronesexamplesbuttontext1}
                      buttonHref={dronesexamplesbuttonhref}
                    />
                  </DronyExample>
                  <DronyExample>
                    <DronyExampleTitle>{dronesexamplestitle2}</DronyExampleTitle>
                    <ContainerWithButton 
                      buttonType="blue" 
                      buttonHref="/" 
                      text={dronesexamplestext2}
                      title={dronesexamplessubtitle2}
                      author={author}
                      phoneNumber={phoneNumber}
                      user={user}
                      email={email}
                    />
                  </DronyExample>
        </StyledDronyExamples>
     );
}
 
export default DronyExamples;